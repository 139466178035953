import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

const getDefaultState = () => ({
  isLoading: false,
  restrictedRecords: [],
  filteredColumnList: [],
  totalRecords: 0
})

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getRestrictedRecords({ commit, dispatch }) {
      commit('SET_LOADING', true)
      try {
        // Simulate or do real API:
        const response = await Vue.prototype.$http.get(
          '/dmp/restricted-entities'
        )
        if (response && response.data.status === 200) {
          const dataObj = response.data.data
          const { data = [], totalRows = 0 } = dataObj
          commit('SET_RESTRICTED_RECORDS', data)
          commit('SET_TOTAL_RECORDS', totalRows)
        }
      } catch (err) {
        console.log('EREROR', err)
        Snackbar({
          message: 'Failed to fetch the records',
          type: 'is-danger',
          indefinite: true
        })
      } finally {
        commit('SET_LOADING', false)
      }
    },
    addFilteredColumnList({ commit, state }, columnObj) {
      const { columnName, isFilter } = columnObj
      let columnList = [...state.filteredColumnList]
      if (isFilter) {
        columnList = [...columnList, columnName]
      } else {
        const index = columnList.indexOf(columnName)
        if (index > -1) {
          columnList.splice(index, 1)
        }
      }
      columnList = [...new Set(columnList)]
      commit('SET_FILTERED_COLUMN_LIST', columnList)
    },
    updateFilteredColumnList({ commit }, filteredColumnList) {
      commit('SET_FILTERED_COLUMN_LIST', filteredColumnList)
    },
    updateTotalRecords({ commit }, totalRecords) {
      commit('SET_TOTAL_RECORDS', totalRecords)
    }
  },
  mutations: {
    SET_LOADING(state, bool) {
      state.isLoading = bool
    },
    SET_RESTRICTED_RECORDS(state, records) {
      state.restrictedRecords = records
    },
    SET_FILTERED_COLUMN_LIST(state, filteredColumnList) {
      state.filteredColumnList = filteredColumnList
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.totalRecords = totalRecords
    }
  }
}
