// Roles
export const userRoles = {
  DIRECT_SUBMITTER: 'DMP Direct-Submitter',
  ANALYST_SUBMITTER: 'DMP Analyst-Submitter',
  ANALYST_MAKER: 'DMP Analyst-Maker',
  ANALYST_CHECKER: 'DMP Analyst-Checker',
  PG_M: 'DMP PgM',
  SUPER_ADMIN: 'DMP Superadmin',
  PM_MANAGER: 'DMP PM Manager'
}

// Path mapping with permission
const dmpPermissionRules = {
  'exception-handling': {
    [userRoles.SUPER_ADMIN]: true,
    [userRoles.PM_MANAGER]: true,
    [userRoles.PG_M]: true
  },
  'assigned-entities': {
    [userRoles.ANALYST_MAKER]: true,
    [userRoles.ANALYST_CHECKER]: true,
    [userRoles.DIRECT_SUBMITTER]: true
  },
  dashboard: {
    [userRoles.PM_MANAGER]: true,
    [userRoles.PG_M]: true
  },
  'submit-for-review': {
    [userRoles.ANALYST_MAKER]: true
  },
  'submit-for-delivery': {
    [userRoles.ANALYST_CHECKER]: true,
    [userRoles.DIRECT_SUBMITTER]: true,
    [userRoles.PM_MANAGER]: true
  },
  'view-entity': {
    [userRoles.SUPER_ADMIN]: true,
    [userRoles.PM_MANAGER]: true,
    [userRoles.PG_M]: true,
    [userRoles.ANALYST_MAKER]: true,
    [userRoles.ANALYST_CHECKER]: true,
    [userRoles.DIRECT_SUBMITTER]: true
  },
  'edit-entity': {
    [userRoles.ANALYST_MAKER]: true,
    [userRoles.ANALYST_CHECKER]: true,
    [userRoles.DIRECT_SUBMITTER]: true,
    [userRoles.PM_MANAGER]: true
  },
  'corporate-action': {
    [userRoles.PM_MANAGER]: true,
    [userRoles.PG_M]: true
  },
  'restricted-entities': {
    [userRoles.PM_MANAGER]: true,
    [userRoles.PG_M]: true
  },
  'submit-for-staging': {
    [userRoles.DIRECT_SUBMITTER]: true,
    [userRoles.ANALYST_CHECKER]: true
  },
  'user-management': {
    [userRoles.PG_M]: true
  }
}
// Check the path permission based on role
// Created for DMP Navigation
export function checkPathPermission(path, roles) {
  // Convert roles to an array if it's a string
  const roleArray = roles ? (typeof roles === 'string' ? [roles] : roles) : []

  // check If path includes the permission key
  const pathKey = Object.keys(dmpPermissionRules).find((ruleKey) =>
    path.includes(ruleKey)
  )

  // Check if the path exists in the permission rules
  if (pathKey && dmpPermissionRules.hasOwnProperty(pathKey)) {
    // Check if at least one role in the array has permission for the given path
    return roleArray.some((role) => dmpPermissionRules[path][role] === true)
  } else {
    // Path not found in rules, deny access
    return false
  }
}
