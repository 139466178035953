import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import { clearStorage, getModuleToNavigate } from '@/util/util.js'
import { checkPathPermission, userRoles } from '@/util/permissions.utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/resetPassword/:resetToken',
    name: 'ResetPassword',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/recoverPassword',
    name: 'recoverPassword',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/passwordRecovered',
    name: 'passwordRecovered',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/profile-setup',
    name: 'profile setup',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/pageNotFound',
    name: 'notFound',
    meta: { group: 'pageNotFound' },
    component: () => import('../views/pageNotFound/PageNotFound.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    meta: { group: 'privacyPolicy' },
    component: () => import('../views/Legal/PrivacyPolicy.vue')
  },
  {
    path: '/tnc',
    name: 'tnc',
    meta: { group: 'tnc' },
    component: () => import('../views/Legal/TermsAndConditions.vue')
  },
  {
    path: '/redirect',
    name: 'redirectAuth',
    component: () => import('../views/redirectAuth/redirectAuth.vue')
  },
  {
    path: '/user/create',
    name: 'createUser',
    component: () => import('../views/User/CreateUser.vue')
  },
  {
    path: '/invite/:token',
    name: 'invite',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('../views/forbidden/Forbidden.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/User/Settings/Settings.vue'),
    redirect: '/settings/profile',
    children: [
      {
        path: 'profile',
        name: 'profile',
        meta: { module: 'settings' },
        component: () =>
          import('../views/User/Settings/Profile/UserProfile.vue')
      },
      {
        path: 'people',
        name: 'people',
        meta: { module: 'settings' },
        component: () =>
          import('../views/User/Settings/TeamManagement/TeamManagement.vue')
      },
      {
        path: 'security-and-permissions',
        name: 'security-and-permissions',
        meta: { module: 'settings', needPermissionCheck: true },
        component: () => import('../views/User/Settings/SSO/SSO.vue')
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin/views/Admin/Admin.vue'),
    redirect: '/admin/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Dashboard/Dashboard.vue')
      },
      {
        path: 'news-categories',
        name: 'news-categories',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/NewsCategories/NewsCategories.vue')
      },
      {
        path: 'news-categories/add',
        name: 'add-news-category',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/NewsCategories/AddNewsCategory.vue')
      },
      {
        path: 'news-categories/update/:id',
        name: 'update_news_category',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/NewsCategories/UpdateNewsCategory.vue')
      },
      {
        path: 'news-rss-feeds',
        name: 'news-rss-feeds',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/NewsRssFeed/NewsRssFeed.vue')
      },
      {
        path: 'client',
        name: 'client',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/Clients/ClientsContainer.vue')
      },
      {
        path: 'client/update/:id',
        name: 'update_client',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/Clients/UpdateClientContainer.vue')
      },
      {
        path: 'client/view/:id',
        name: 'view_client',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/Clients/UpdateClientContainer.vue')
      },
      {
        path: 'company',
        name: 'company',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Companies/Companies.vue')
      },
      {
        path: 'company/add',
        name: 'add_company',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Companies/AddCompany.vue')
      },
      {
        path: 'company/update/:id',
        name: 'updateCompany',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/Companies/UpdateCompany.vue')
      },
      {
        path: 'category',
        name: 'category',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/Categories/Categories.vue')
      },
      {
        path: 'category/add',
        name: 'add_category',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/Categories/AddCategory.vue')
      },
      {
        path: 'category/update/:id',
        name: 'update_category',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/Categories/UpdateCategory.vue')
      },
      {
        path: 'product',
        name: 'product',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Products/Products.vue')
      },
      {
        path: 'product/add',
        name: 'add_product',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Products/AddProduct.vue')
      },
      {
        path: 'product/update/:id',
        name: 'update_product',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/Products/UpdateProduct.vue')
      },
      {
        path: 'source',
        name: 'source',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/DataSources/DataSources.vue')
      },
      {
        path: 'source/add',
        name: 'add_source',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/DataSources/AddDataSource.vue')
      },
      {
        path: 'source/update/:id',
        name: 'update_source',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/DataSources/UpdateDataSource.vue')
      },
      {
        path: 'topic',
        name: 'topic',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Topics/Topics.vue')
      },
      {
        path: 'topic/add',
        name: 'add_topic',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Topics/AddTopic.vue')
      },
      {
        path: 'topic/update/:id',
        name: 'update_topic',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Topics/UpdateTopic.vue')
      },
      {
        path: 'company_size',
        name: 'company_size',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/CompanySize/CompanySize.vue')
      },
      {
        path: 'company_size/add',
        name: 'add_company_size',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/CompanySize/AddCompanySize.vue')
      },
      {
        path: 'company_size/update/:id',
        name: 'update_company_size',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/CompanySize/UpdateCompanySize.vue')
      },
      {
        path: 'industry',
        meta: { module: 'admin' },
        name: 'industry',
        component: () => import('../views/Admin/views/Industry/Industry.vue')
      },
      {
        path: 'industry/add',
        name: 'add_industry',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Industry/AddIndustry.vue')
      },
      {
        path: 'industry/update/:id',
        name: 'update_industry',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/Industry/UpdateIndustry.vue')
      },
      {
        path: 'job',
        name: 'job',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Jobs/Job.vue')
      },
      {
        path: 'roles-and-permissions',
        name: 'roles-and-permissions',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/RolesAndPermissions/Roles/Roles.vue')
      },
      {
        path: 'roles-and-permissions/add',
        name: 'add_roles_and_permissions',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/RolesAndPermissions/Roles/AddRole.vue')
      },
      {
        path: 'roles-and-permissions/update/:id',
        name: 'update_roles_and_permissions',
        meta: { module: 'admin' },
        component: () =>
          import(
            '../views/Admin/views/RolesAndPermissions/Roles/UpdateRole.vue'
          )
      },
      {
        path: 'feature',
        name: 'feature',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Features/Features.vue')
      },
      {
        path: 'feature/add',
        name: 'add_feature',
        meta: { module: 'admin' },
        component: () => import('../views/Admin/views/Features/AddFeatures.vue')
      },
      {
        path: 'feature/update/:id',
        name: 'update_feature',
        meta: { module: 'admin' },
        component: () =>
          import('../views/Admin/views/Features/UpdateFeatures.vue')
      }
    ]
  },
  {
    path: '/marketing',
    name: 'marketing',
    meta: { module: 'tam' },
    component: () => import('../views/MainPage/MainContainer.vue')
  },
  {
    path: '/voe',
    name: 'voe',
    component: () => import('../views/MainPage/MainContainer.vue'),
    redirect: '/voe/summary',
    children: [
      {
        path: 'summary',
        name: 'voeSummary',
        meta: {
          module: 'voe',
          group: 'statistics'
        },
        component: () => import('../views/VOE/VoeSummary/VoeSummary.vue')
      },
      {
        path: 'comments',
        name: 'voeComments',
        meta: { module: 'voe', group: 'comments' },
        component: () => import('../views/VOE/TrendingBuzz/TrendingBuzz.vue')
      },
      {
        path: 'reports',
        name: 'voeReports',
        meta: { module: 'voe', group: 'statistics' },
        component: () => import('../views/Report/Report.vue')
      }
    ]
  },
  {
    path: '/voe-drivers',
    name: 'voe-drivers',
    meta: { module: 'voe', group: 'drivers' },
    component: () => import('../views/MainPage/MainContainer.vue'),
    redirect: '/voe-drivers/esat',
    children: [
      {
        path: 'esat/',
        name: 'voeEsat',
        meta: { module: 'voe', group: 'drivers' },
        component: () => import('../views/VOE/ESatDrivers/ESatDrivers.vue')
      },
      {
        path: 'evp-matrix/',
        name: 'voeEvpMatrix',
        meta: { module: 'voe', group: 'drivers' },
        component: () => import('../views/VOE/ESatDrivers/EVPMatrix.vue')
      },
      {
        path: 'trends',
        name: 'voeTrends',
        meta: { module: 'voe', group: 'drivers' },
        component: () =>
          import('../views/VOE/TrendingDeclining/TrendingDeclining.vue')
      }
    ]
  },
  {
    path: '/esg-controversies',
    name: 'esgControversies',
    meta: { module: 'esg-controversies' },
    component: () => import('../views/MainPage/MainContainer.vue'),
    redirect: { name: 'esg-controversies' },
    children: [
      {
        path: 'p/:pageNumber(\\d+)',
        name: 'esg-controversies',
        meta: { module: 'esg-controversies', group: 'esg-controversies' },
        component: () => import('../views/ESG/EsgMain.vue')
      },
      {
        path: 'article/:articleId',
        name: 'esgControversyArticle',
        props: true,
        meta: { module: 'esg-controversies', group: 'esg-controversies' },
        component: () => import('../views/ESG/ESGArticle.vue')
      }
    ]
  },
  {
    path: '/esg-daas',
    name: 'esgdaas',
    meta: { module: 'esg-daas' },
    component: () => import('../views/MainPage/MainContainer.vue'),
    children: [
      {
        path: '',
        name: 'esg-daas',
        meta: { module: 'esg-daas', group: 'esg-daas' },
        component: () => import('../views/EsgDaas/EsgDaas.vue')
      }
    ]
  },
  {
    path: '/playbook',
    name: 'playBook',
    meta: { module: 'playbook' },
    component: () => import('../views/MainPage/MainContainer.vue'),
    children: [
      {
        path: '',
        name: 'playbook',
        meta: { module: 'playbook', group: 'playbook' },
        component: () => import('../views/PlayBook/PlayBook.vue')
      }
    ]
  },
  {
    path: '/reports-repository',
    name: 'reports-repository',
    meta: { module: 'reports-repository' },
    redirect: { name: 'equityReports' },
    component: () => import('../views/MainPage/MainContainer.vue'),
    children: [
      {
        path: 'ir/equity',
        name: 'equityReports',
        meta: {
          module: 'reports-repository',
          group: 'investment-research'
        },
        component: () => import('../views/ReportsRepository/ReportsMain.vue')
      },
      {
        path: 'ir/fixed-income',
        name: 'fixedIncome',
        meta: {
          module: 'reports-repository',
          group: 'investment-research'
        },
        component: () => import('../views/ReportsRepository/ReportsMain.vue')
      },
      {
        path: 'add/single-report',
        name: 'addSingleReport',
        meta: {
          module: 'reports-repository',
          group: 'investment-research',
          hideSidePanel: true,
          hideNestedTopNav: true
        },
        component: () =>
          import('../views/ReportsRepository/AddSingleReport.vue')
      },
      {
        path: 'add/multiple-report',
        name: 'addMultipleReport',
        props: true,
        meta: {
          module: 'reports-repository',
          group: 'investment-research',
          hideSidePanel: false,
          hideFilters: true,
          hideNestedTopNav: true
        },
        component: () =>
          import('../views/ReportsRepository/AddMultipleReport.vue')
      },
      {
        path: 'ir/:reportType(fixed-income|equity)/share/:reportId',
        name: 'sharedReport',
        props: true,
        meta: {
          module: 'reports-repository',
          hideSidePanel: true,
          hideFilters: true,
          hideNestedTopNav: true
        },
        component: () => import('../views/ReportsRepository/ReportsShared.vue')
      }
    ]
  },
  {
    path: '/investment-insight',
    name: 'investmentInsight',
    meta: { module: 'investment-insight' },
    component: () => import('../views/MainPage/MainContainer.vue'),
    children: [
      {
        path: '',
        name: 'investment-insight',
        meta: { module: 'investment-insight', group: 'investment-insight' },
        component: () =>
          import('../views/InvestmentInsight/InvestmentInsight.vue')
      }
    ]
  },
  {
    path: '/dmp',
    name: 'dataManagementPlatoform',
    meta: { module: 'data-management-platform' },
    component: () => import('../views/MainPage/MainContainer.vue'),
    children: [
      {
        path: '',
        name: 'data-management-platform',
        meta: {
          module: 'data-management-platform',
          group: 'data-management-platform'
        },
        component: () => import('../views/DataManagement/DataManagement.vue'),
        children: [
          {
            path: 'dashboard',
            name: 'dmpDashboard',
            meta: {
              module: 'data-management-platform',
              group: 'data-management-platform',
              subGroup: 'Dashboard'
            },
            component: () => import('../views/DataManagement/DMPDashboard.vue')
          },
          {
            path: 'workflow-management/assigned-entities',
            name: 'assigned-entities',
            meta: {
              module: 'data-management-platform',
              group: 'workflow-management'
            },
            component: () =>
              import(
                '../views/DataManagement/WorkFlowManagement/WorkFlowManagementContent.vue'
              )
          },
          {
            path: 'workflow-management/exception-handling',
            name: 'exception-handling',
            meta: {
              module: 'data-management-platform',
              group: 'workflow-management'
            },
            component: () =>
              import(
                '../views/DataManagement/WorkFlowManagement/ExceptionHandling/ExceptionHandlingContent.vue'
              )
          },
          {
            path: 'workflow-management/corporate-action-details',
            name: 'corporate-action-details',
            meta: {
              module: 'data-management-platform',
              group: 'workflow-management'
            },
            component: () =>
              import(
                '../views/DataManagement/WorkFlowManagement/CorporateActionDetails/CorporateActionDetails.vue'
              )
          },
          {
            path: 'workflow-management/restriced-entities',
            name: 'restriced-entities',
            meta: {
              module: 'data-management-platform',
              group: 'workflow-management'
            },
            component: () =>
              import(
                '../views/DataManagement/WorkFlowManagement/RestrictedRecords/RestrictedRecords.vue'
              )
          },
          {
            path: 'workflow-management/integration',
            name: 'integration',
            meta: {
              module: 'data-management-platform',
              group: 'workflow-management'
            },
            component: () =>
              import('../views/DataManagement/DMPIntegration.vue')
          },
          {
            path: 'entities',
            name: 'entities',
            meta: {
              module: 'data-management-platform',
              group: 'data-management-platform',
              subGroup: 'Entities'
            },
            component: () => import('../views/DataManagement/DMPEntities.vue')
          },
          {
            path: 'add-entities',
            name: 'addEntities',
            meta: {
              module: 'data-management-platform',
              group: 'data-management-platform'
            },
            component: () => import('../views/DataManagement/AddEntities.vue')
          },
          {
            path: 'workflow-management/view-entity',
            name: 'viewEntity',
            meta: {
              module: 'data-management-platform',
              group: 'data-management-platform'
            },
            component: () => import('../views/DataManagement/ViewEntity.vue'),
            props: (route) => ({ viewSGAId: route.params.viewSGAId })
          },
          {
            path: 'exception-handling/duplicates',
            name: 'exception-handling-duplicates',
            meta: {
              module: 'data-management-platform',
              group: 'data-management-platform',
              subGroup: 'Exception-handling'
            },
            component: () =>
              import(
                '../views/DataManagement/WorkFlowManagement/ExceptionHandling/ExceptionHandlingDuplicates.vue'
              )
          },
          {
            path: 'user-management',
            name: 'userManagement',
            meta: {
              module: 'data-management-platform',
              group: 'data-management-platform',
              subGroup: 'user-management'
            },
            component: () =>
              import(
                '../views/DataManagement/UserManagement/DMPUserManagement.vue'
              )
          }
        ]
      }
    ]
  },
  {
    path: '/publication-monitoring-tool',
    name: 'publication-monitoring-tool',
    meta: { module: 'publication-monitoring-tool' },
    component: () => import('../views/MainPage/MainContainer.vue'),
    redirect: '/publication-monitoring-tool/documents',
    children: [
      {
        path: 'documents',
        name: 'documents',
        meta: { module: 'publication-monitoring-tool', group: 'documents' },
        component: () => import('../views/PublicationMonitoringTool/Pmt.vue')
      },
      {
        path: 'documents/add',
        name: 'add-new-document',
        meta: { module: 'publication-monitoring-tool', group: 'documents' },
        component: () =>
          import('../views/PublicationMonitoringTool/AddDocuments.vue')
      },
      {
        path: 'dashboard',
        name: 'PMTdashboard',
        meta: { module: 'publication-monitoring-tool', group: 'dashboard' },
        component: () => import('../views/PublicationMonitoringTool/Pmt.vue')
      }
    ]
  },

  {
    path: '/tam',
    name: 'cat',
    redirect: { name: 'tam' },
    meta: { module: 'tam', group: 'tam' },
    component: () => import('../views/MainPage/MainContainer.vue'),
    children: [
      {
        path: '',
        name: 'tam',
        meta: { module: 'tam', group: 'tam' },
        component: () => import('../views/TAM/TamMain.vue')
      }
    ]
  },
  {
    path: '/voc',
    name: 'voc',
    meta: { module: 'voc' },
    component: () => import('../views/MainPage/MainContainer.vue'),
    redirect: '/voc/summary',
    children: [
      {
        path: 'summary',
        name: 'summary',
        meta: { module: 'voc', group: 'statistics' },
        component: () => import('../views/VocSummary/VocSummary.vue')
      },
      {
        path: 'comments',
        name: 'comments',
        meta: { module: 'voc', group: 'comments' },
        component: () => import('../views/TrendingBuzz/TrendingBuzz.vue')
      },
      {
        path: 'reports',
        name: 'reports',
        meta: { module: 'voc', group: 'statistics' },
        component: () => import('../views/Report/Report.vue')
      },
      {
        path: 'nps',
        name: 'nps',
        meta: { module: 'voc', group: 'statistics' },
        component: () => import('../views/NPS/NPS.vue')
      },
      {
        path: 'voc-drivers',
        redirect: '/voc-drivers/csat'
      },
      {
        path: '/voc-drivers/csat',
        name: 'csat',
        meta: { module: 'voc', group: 'drivers' },
        component: () => import('../views/CSatDrivers/CSatDrivers.vue')
      },
      {
        path: '/voc-drivers/vendor-csat',
        name: 'vendor-csat',
        meta: { module: 'voc', group: 'drivers' },
        component: () => import('../views/CSatDrivers/VendorCsat.vue')
      },
      {
        path: '/voc-drivers/trends',
        name: 'trends',
        meta: { module: 'voc', group: 'drivers' },
        component: () => import('../views/SunriseSunset/SunriseSunset.vue')
      },
      {
        path: '/voc-drivers/competitive-positioning',
        name: 'competitive-positioning',
        meta: { module: 'voc', group: 'drivers' },
        component: () => import('../views/winLoss/WinLoss.vue')
      },
      {
        path: '/c-sat',
        name: 'c-sat',
        component: () => <div>hello</div>
      }
    ]
  },
  {
    path: '/',
    name: 'inava-root',
    meta: { module: '*' },
    component: () => import('../views/MainPage/MainContainer.vue')
  },
  {
    path: '*',
    name: 'star',
    redirect: '/pageNotFound'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'esg-controversies') {
    if (!to.params || !to.params.pageNumber) {
      next('/esg-controversies/p/1')
    }
  }
  navigate(to, from, next)
})

router.beforeResolve((to, from, next) => {
  const user = store.state.user.userDetails
  const obj = [
    { name: 'add', alias: 'write' },
    { name: 'update', alias: 'update' },
    { name: 'view', alias: 'read' }
  ]
  if (to.path.includes('/admin') && !to.path.includes('/dashboard')) {
    const data = user.permissions
      ? user.permissions.filter((data) => to.path.includes(data.path))
      : []
    let result = obj.filter((data) => to.path.includes(data.name))
    if (!result.length) result = [{ name: 'read', alias: 'read' }]
    if (data.length) {
      if (!data[0][result[0].alias]) {
        next(getModuleToNavigate(user))
      } else {
        next()
      }
    } else {
      next(getModuleToNavigate(user))
    }
  } else if (to.meta.needPermissionCheck) {
    const pathPermissions = user.permissions
      ? user.permissions.filter((permit) => {
          const pathParts = to.path.split('/')
          return pathParts[pathParts.length - 1].includes(permit.path)
        })
      : []
    if (pathPermissions.length) {
      if (!pathPermissions[0][obj[1].alias]) {
        next(getModuleToNavigate(user))
      } else {
        next()
      }
    } else {
      next(getModuleToNavigate(user))
    }
  } else if (to.path.includes('dmp')) {
    // Added DMP Routes Authorization
    if (
      to.path.replaceAll('/', '') === 'dmp' ||
      (to.name === 'entities' && store.state.dmp.isAddEntitiesEnabled)
    ) {
      // Default Redirection Logic
      const landingRoute = dmpLandingRoute()
      if (landingRoute) {
        next({ name: landingRoute })
      } else {
        next()
      }
    } else if (to.name === 'exception-handling') {
      if (!checkPathPermission('exception-handling', user.roleName)) {
        next('/forbidden')
      }
    } else if (to.name === 'dmpDashboard') {
      if (!checkPathPermission('dashboard', user.roleName)) {
        next('/forbidden')
      }
    } else if (to.name === 'assigned-entities') {
      if (!checkPathPermission('assigned-entities', user.roleName)) {
        next('/forbidden')
      }
    } else if (to.name === 'viewEntity') {
      if (!checkPathPermission('view-entity', user.roleName)) {
        next('/forbidden')
      }
    } else if (to.name === 'entities') {
      if (!checkPathPermission('edit-entity', user.roleName)) {
        next('/forbidden')
      }
    } else if (to.name === 'exception-handling-duplicates') {
      if (!checkPathPermission('exception-handling', user.roleName)) {
        next('/forbidden')
      }
    } else if (to.name === 'corporate-action-details') {
      if (!checkPathPermission('corporate-action', user.roleName)) {
        next('/forbidden')
      }
    } else if (to.name === 'userManagement') {
      if (!checkPathPermission('user-management', user.roleName)) {
        next('/forbidden')
      }
    }
    next()
  } else {
    if (to.matched.some((route) => route.name === 'Admin')) {
      const adminPermissions = user.permissions
        ? user.permissions.filter((permission) => {
            return !permission.is_site_feature && permission.read
          })
        : []
      if (!adminPermissions.length) {
        next(getModuleToNavigate(user))
        return
      }
    }
    next()
  }
})

async function navigate(to, from, next) {
  const token = window.localStorage.getItem('access-token')
  if (token == null) {
    // if token is not present
    if (to.path.endsWith('login')) {
      clearStorage(false)
      next()
    } else if (to.path.includes('redirect') || to.path.includes('invite')) {
      next()
    } else if (
      to.path.includes('resetPassword') ||
      to.path.endsWith('recoverPassword') ||
      to.path.endsWith('forgotPassword') ||
      to.path.endsWith('passwordRecovered') ||
      to.path.endsWith('tnc') ||
      to.path.endsWith('privacy-policy')
    ) {
      next()
    } else {
      clearStorage(false)
      if (to.path === '/') {
        next('/login')
        return
      }
      next({ path: '/login', query: { redirect: to.path } })
    }
  } else {
    // if token is present
    if (to.path.includes('profile-setup')) {
      next()
      return
    }
    if (store.state.user.userDetails.email === undefined) {
      try {
        await store.dispatch('user/getUserDetails')
        if (store.state.user.userDetails.preferences != null) {
          store.state.common.user_preference_mode =
            store.state.user.userDetails.preferences.preference_mode
        }
      } catch (err) {
        next()
      }
    }
    const user = store.state.user.userDetails
    if (isErrorPath(to.path)) {
      // update it to more generic allowed paths
      next()
      return
    }
    if (to.path.endsWith('login') || to.path.endsWith('login/')) {
      if (user && user !== 'undefined') {
        next(getModuleToNavigate(user))
      } else {
        next()
      }
    } else if (user && user !== 'undefined') {
      if (to.meta.module === '*') {
        next(getModuleToNavigate(user))
        return
      }
      if (to.path.includes('admin')) {
        if (user.permissions && user.permissions.length) {
          ;[...user.permissions.filter((obj) => obj.read)].length
            ? next()
            : next(getModuleToNavigate(user))
        } else {
          next(getModuleToNavigate(user))
        }
      } else if (
        to.path.includes('redirect') ||
        to.path.includes('profile-setup') ||
        to.path.includes('invite')
      ) {
        next(getModuleToNavigate(user))
      } else if (
        to.path.includes('resetPassword') ||
        to.path.endsWith('recoverPassword') ||
        to.path.endsWith('forgotPassword') ||
        to.path.endsWith('passwordRecovered')
      ) {
        next(getModuleToNavigate(user))
      } else {
        if (user.modules.map((d) => d.module_name).includes(to.meta.module)) {
          next() // normal nav
        } else if (
          to.path.endsWith('tnc') ||
          to.path.endsWith('privacy-policy')
        ) {
          next()
        } else {
          next('/forbidden')
        }
      }
    } else {
      if (user.modules.map((d) => d.module_name).includes(to.meta.module)) {
        next() // normal nav
      } else {
        next('/forbidden')
      }
    }
  }
}

function isErrorPath(path) {
  if (path.endsWith('pageNotFound') || path.endsWith('forbidden')) {
    return true
  } else {
    return false
  }
}

function dmpLandingRoute() {
  const role = store.state.user.userDetails.roleName
  if (role === userRoles.SUPER_ADMIN) {
    return 'exception-handling'
  } else if (checkPathPermission('dashboard', role)) {
    return 'dmpDashboard'
  } else if (checkPathPermission('assigned-entities', role)) {
    return 'assigned-entities'
  } else {
    return 'forbidden'
  }
}

export default router
