import Vue from 'vue'
import Vuex from 'vuex'
import categories from './admin/categories'
import newsCategories from './admin/newsCategories'
import newsRssFeed from './admin/newsRssFeed'
import clients from './admin/clients'
import company from './admin/company'
import modules from './admin/modules'
import companies from './admin/companies'
import dataSource from './admin/dataSources'
import industries from './admin/industries'
import products from './admin/products'
import subscriptions from './admin/subscriptions'
import topics from './admin/topics'
import users from './admin/users'
import analytics from './analytics'
import auth from './auth'
import invitation from './client/invitation'
import saml from './client/saml'
import common from './common'
import csatdriver from './csatdriver'
import feedback from './feedback'
import filters from './filters'
import report from './report'
import trendingbuzz from './trendingbuzz'
import user from './user'
import vocsummary from './vocsummary'
import voesummary from './voesummary'
import adminCommon from './admin/common'
import client from './client'
import jobs from './admin/jobs'
import sunriseSunset from './sunriseSunset'
import role from './admin/role'
import feature from './admin/feature'
import winLoss from './winLoss'
import tam from './tam'
import esatdriver from './esatdriver'
import esgControversies from './esgControversies'
import esgDaas from './esgDaas'
import dmp from './DMP/dmp'
import pmtDocuments from './PMT/pmtDocuments'
import assignedEntities from './DMP/assignedEntities'
import basicDetails from './DMP/basicDetails'
import workflow from './DMP/workflow'
import audit from './DMP/audit'
import anaCredit from './DMP/anaCredit'
import reportsRepository from './reportsRepository'
import evidence from './DMP/evidence'
import reportsRepoFilters from './filters.reportsRepository'
import details2052a from './DMP/details2052a'
import identifiers from './DMP/identifiers'
import industry from './DMP/industry'
import relationship from './DMP/relationship'
import review from './DMP/review'
import pmDashboard from './DMP/pmDashboard'
import exceptionHandling from './DMP/exceptionHandling'
import corporateAction from './DMP/corporateAction'
import pmtDashboard from './PMT/pmtDashboard'
import pmtDashboardFilters from './PMT/pmtDashboardFilters'
import teamManagement from './DMP/teamManagement'
import userManagement from './DMP/userManagement'
import restrictedEntities from './DMP/restrictedEntities'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    filters,
    user,
    report,
    csatdriver,
    esatdriver,
    common,
    analytics,
    feedback,
    trendingbuzz,
    vocsummary,
    clients,
    modules,
    products,
    users,
    categories,
    dataSource,
    companies,
    subscriptions,
    topics,
    industries,
    auth,
    saml,
    invitation,
    adminCommon,
    client,
    company,
    jobs,
    sunriseSunset,
    role,
    feature,
    winLoss,
    tam,
    voesummary,
    newsCategories,
    newsRssFeed,
    esgControversies,
    esgDaas,
    pmtDocuments,
    pmtDashboard,
    pmtDashboardFilters,
    dmp,
    reportsRepository,
    evidence,
    basicDetails,
    audit,
    anaCredit,
    reportsRepoFilters,
    assignedEntities,
    details2052a,
    identifiers,
    workflow,
    industry,
    relationship,
    review,
    pmDashboard,
    exceptionHandling,
    corporateAction,
    teamManagement,
    userManagement,
    restrictedEntities
  },
  actions: {
    resetState({ commit, dispatch }, value) {
      dispatch('filters/resetFilterState')
      dispatch('user/resetUserState')
      dispatch('report/resetReportState')
      dispatch('common/resetCommonState')
    }
  }
})
